export const fengSettings = {
  name: 'maracanaclub',
  version: '0.0.1',
  hmr: true,
  projectName: 'Maracanã Club',
  clientName: 'Maracanã',
  clientId: 'CNPJ 17.831.131/0001-13',
  clientPhone: '(21) 2222-2222',
  clientEmail: 'atendimento@maracanaclub.com.br',
  clientAddress: 'Avenida Cidade Lima, 86, SANTO CRISTO - Rio de Janeiro - RJ',
  cache: {
    strategy: 'explicit',
    localStorageKey: 'fengHttpCache',
    ttl: 60000 // 1min (60000) - 5min (300000) - 30min (1800000) - 30min (1800000) - 1h (3600000) - 2h (7200000) - 6h (21600000) - 12h (43200000) - 24h (86400000)
  },
  auth: {
    enableAdmin: false,
    enableSt: false,
    enableResale: false,
    enableCaptcha: true,
    verifyCPF: true,
    verifyEmail: true,
    keepConnected: true,
    loginForm: {
      title: 'Para primeiro acesso ao novo sistema, clique abaixo em Gerar nova senha para receber no seu e-mail cadastrado.',
      text: 'Email/CPF',
      forgotPass: 'Gerar nova senha',
      forgotPassText: 'Preencha com o seu e-mail ou CPF cadastrado e enviaremos um código de recuperação para você. O e-mail só será enviado caso o cadastro seja encontrado.',
      forgotEmailText: 'Caso você tenha esquecido o seu e-mail, entre em contato com o atendimento <a href="/atendimento">clicando aqui</a>.',
      forgotAccountLabel: 'E-mail/CPF'
    },
    firstAccess: {
      enable: true,
      migrationLogic: false,
      ask: {
        password: false,
        terms: true,
        missingData: true
      }
    },
    social: [
        { name: 'google', enabled: false },
        { name: 'facebook', enabled: false }
    ],
    register: {
      enableSt: false,
      showTerms: true,
      fields: {
        genre: false,
        clubId: true,
      }
    },
    staging: {
      enable: false,
      title: 'SEJA BEM-VINDO!',
      subtitle: 'faça parte da torcida que conduz o time',
      description: `
        <p>
          Bloqueio de navegação para ambiente de staging <strong>em fase de teste</strong>.
        </p>
        <p>
          Para liberar navegação, adicione <strong>/login</strong> no final da URL desta página
          e efetue o login com um <strong>usuário administrativo</strong> com
          <strong>perfil 'Administrador', 'Desenvolvedor' ou 'Staging'.
        </p>
        <p>
          <strong>Após validação da funcionalidade, esta mensagem será removida.</strong>
        </p>
      `,
    },
  },
  payment: {
    creditCard : {
      maxAllowed: 3,
      allowedTypes: [
        { type: 'visa', regex: new RegExp('^4') },
        { type: 'mastercard', regex: new RegExp('^5[1-5]') },
        { type: 'american-express', regex: new RegExp('^3[47]') },
        { type: 'diners', regex: new RegExp('^30[0-5]') },
        { type: 'jcb', regex: new RegExp('^35(2[89]|[3-8][0-9])') },
        { type: 'visa-electron', regex: new RegExp('^(4026|417500|4508|4844|491(3|7))') },
        { type: 'maestro',regex: new RegExp('^(5000|5018|5020|5038|6304|6759|676[1-3])') },
        { type: 'discover',regex: new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)') },
        { type: 'elo',regex: new RegExp('^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|655000|655001|651652|651653|651654|650485|650486|650487|650488|506699|5067[0-6][0-9]|50677[0-8]|509\d{3})') },
        { type: 'hipercard',regex: new RegExp('^(606282\d{10}(\d{3})?|3841\d{15})') },
      ],
      enableCaptcha: true,
      enableDelete: true
    }
  },
  installments: {
    enableInstallments: false,
  },
  loading: {
    global: 'ring', // default | ring | orbital | pulse
    local: 'bullets' // default | bars | bullets | progress
  },
  homeSettings: {
    scroll: {
      callToAction: {
        enable: true,
        template: 10,
        titleImage: 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-home.pngg',
        link: 'https://lounge.maracanaclub.com.br/'
      },
      hero: true,
      tickets: {
        enable: true,
        alternativeEnable: true,
        template: 5
      },
      tabs: {
        enable: true,
        template: 10
      },
      plans: {
        enable: false
      },
      specialPlans: {
        enabled: false
      },
      exclContent: false,
      testimony: false,
      callToActionFooter: false
    },
    cards: {
      enable: false,
      enableCustomPositionTitle: true,
      enableBackgroundTexture: true,
      enableCustomSizes: true,
      contentBox: true,
      experiences: false,
      plans: false,
      ticketCards: false,
      tickets: false
    },
    plan: {
      dataInicialText: 'DATA CONTRATO',
      hideGuests: true
    },
    header: {
      greetingMsg: `Olá,`,
      legacyMsg: ` `,
      legacyBtnMsg: ` `,
      inactiveMsg: `O seu plano foi bloqueado após 3 meses inadimplência<br>Você pode fazer uma nova adesão. Clique abaixo e saiba mais sobre os planos de sócio com ainda mais benefícios e vantagens.`,
      templateHeader: 3,
      enableWarnings: false
    }
  },
  myAccount: {
    home: {
      showBannerNewLink: true
    },
    menu: {
      plan: true,
      profile: true,
      payment: false,
      dependents: false
    },
    plan: {
      disable: true,
      ticketCard: {
        hideTicketCardCopy: true,
        showImage: false
      }
    },
    profile: {
      hidePartneshipPeriod: true,
      complementary: {
        enabled: true,
        fields: {
          nationality: false,
          rg: false,
          civilStatus: true,
          education: false,
          residentialPhone: false,
          shirtSize: false,
          shortSize: false,
          shoeSize: false,
          income: false,
          interest: false,
          matchFrequency: false,
          hasChildren: true,
          childrenNumber: false,
          profession: false,
          company: false,
          interestWorkField: false,
          club: true,
          religion: false,
          lendChair: true,
          interestInBenefits: true,
          directedExperience: true
        },
        options: {
          shirtSize: [
            { value: 'P', label: 'P'},
            { value: 'M', label: 'M'},
            { value: 'G', label: 'G'},
            { value: 'GG', label: 'GG'},
            { value: '3G', label: '3G'},
            { value: '4G', label: '4G'},
            { value: '7G', label: '7G'},
          ]
        }
      }
    },
    socialNetworks: {
      enabled: true
    }
  },
  virtualTicketCard: {
    template: 4,
    enable: true,
    showVirtualTicketCard: true,
    openVirtualTicketText: 'Visualizar Ingresso Virtual',
    imgLogo: {
      buttonLogo:'https://santos-gs.s3.amazonaws.com/prod/website/logo-santospass-preto.png',
      url: ''
    }
  },
  partnersSettings: {
    enableMoreInfo: false,
  },
  experiencesSettings: {
    menu: {
      catalog: true,
      howToScore: false,
      points: true,
      rescued: true
    },
  },
  faqSettings: {
    faqTemplate: 9,
    mockFaq: false,
    contacts: {
      chat: {
        active: true,
        availability: 'de segunda a sexta, das 8h às 21h, e aos sábados, das 10h às 16h',
      },
      email: {
        active: true
      }
    }
  },
  headerSettings: {
    headerTemplate: 6,
    floatingLogin: false,
    enableCounter: false,
    hideCounterOnPage: ['/transparencia'],
    enableMobileMenu: true,
    hidePoints: true,
    nav: [
      { title: 'HOME', routerLink: '/home', routerLinkActiveOptions:  { exact: true } },
      { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      { title: 'EVENTOS', routerLink: '/eventos', routerLinkActiveOptions: { exact: true } },
      // { title: 'EXPERIÊNCIAS', routerLink: '/experiencias', routerLinkActiveOptions: { exact: false } },
      // { title: 'PARCEIROS', routerLink: '/parceiros', routerLinkActiveOptions: { exact: true } },
      { title: 'ATENDIMENTO', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
    ],
  },
  footerSettings: {
    footerTemplate: 1,
    social: [
      // { icon: 'facebook', href: 'https://www.facebook.com/camisa7bfr' },
      { icon: 'x', href: 'https://x.com/maracana' },
      { icon: 'youtube', href: 'https://www.youtube.com/@maracatv' },
      { icon: 'instagram', href: 'https://www.instagram.com/maracana' }
    ],
    nav: [
      // { title: 'HOME', routerLink: '/', click: '', clickParam: '' },
      // { title: 'MINHA CONTA', routerLink: '/minha-conta/meu-plano', routerLinkActiveOptions: { exact: false } },
      // { title: 'PRÓXIMOS JOGOS', routerLink: '/eventos', routerLinkActiveOptions: { exact: false } },
      // { title: 'EXPERIÊNCIAS', routerLink: '/experiencias', routerLinkActiveOptions: { exact: false } },
      // { title: 'PARCEIROS', routerLink: '/parceiros', routerLinkActiveOptions: { exact: true } },
      // { title: 'FAQ', routerLink: '/atendimento', routerLinkActiveOptions: { exact: true } },
      // { title: 'LOGOUT', routerLink: '', click: 'logout', clickParam: '', if: 'isAuth' },
    ],
    enableTerms: true,
    hideBenefitRegulation: true
  },
  plans: {
    template: 3,
    cardPlaceholder: "https://ngx-feng.s3-sa-east-1.amazonaws.com/images/loading/img-placeholder.png",
    enableDependents: false,
    subscribeText: 'QUERO ESTE'
  },
  specialPlan: {
    template: 2,
  },
  embassies: {
    template: 2
  },
  checkout: {
    register: {
      promptValidation: {
        cpf: false,
        email: false,
        clubId: false,
      }
    },
    defaultDuration: '12',
    maxGuests: true,
    showPaymentMethods: true,
    comingSoonPaymentSlip: false,
    modalSixPeriodicity: {
      enabled: false,
      title: 'ATENÇÃO',
      message: `
        Os planos com duração semestral não possuem o benefício do kit de boas-vindas.
        Você receberá apenas a carteirinha. Caso deseje receber o kit, altere a periodicidade para Anual.
      `
    },
    offState: {
      state: 'RJ',
      modalOffState: {
        enabled: true,
        title: 'ATENÇÃO',
        message: `
          Você selecionou um plano fora do estado do Rio de Janeiro, iremos te redirecionar para página de planos para selecionar o plano correto.
        `
      }
    },
    successPage: {
      enabled: true,
      title: 'Pagamento processado!',
      message: `Seja bem vindo ao Maracana Club.`
    },
    boletoPage: {
      enabled: true,
      title: 'Boleto gerado com sucesso!',
      message: `Agora falta pouco! Pague o seu boleto até a data de vencimento.`
    },
    paymentFailPage: {
      enabled: true,
      title: 'Ocorreu um erro ao efetuar o seu pagamento!',
      message: `Não foi possível processar o seu pagamento na forma de pagamento selecionada.`
    }
  },
  events: {
    shield: 'https://images.mengo.com.br/prod/assets/images/club-shield.png',
    background: 'https://maracana.s3.sa-east-1.amazonaws.com/prod/assets/images/bg-home.png',
    name: 'MARACANÃ',
    imageMoreInformation: 'https://ngx-feng.s3.sa-east-1.amazonaws.com/images/event-card-more-info.png',
    showMoreInfo: true,
    buttonBuyTickets: 'Adicionar Produto',
    onlyTeamsNames: true,
    customLoginButton: 'Acesse seu cadastro'
  },
  terms: {
    splitted: true,
    termsURL: {
      url: null,
      route: '/termos-e-politicas'
    },
    privacyURL: {
      url: null,
      route: '/termos-e-politicas'
    }
  }
}