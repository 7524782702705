export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.stg.maracanaclub.com.br/',
  weURL: 'https://admin.stg.maracanaclub.com.br/',
  recaptcha: {
    key: '6LdMmRIgAAAAAIUM-APhJF9dMs3d-5clai_iU9X8'
  },
  auth: {
    social: [
    ],
    cognito: {
      enable: false
    }
  }
};