<fengstui-global-loading></fengstui-global-loading>
<div
  class="app"
  [ngClass]="{
    'app--logged': (isAuth | async),
    'app--otherpages': (!(isHome | async) && !(isAuth | async)),
    'app--dependent': (isDependent | async),
    'app--staging': !fengEnv?.production && !fengEnv?.maintenance && fengSettings?.auth?.staging?.enable && (isAuthStaging | async),
    'app--resale': (isResale | async),
    'app--loading': (isLoading | async)
  }"
>

  <fengstlayout-staging-bar
    *ngIf="!fengEnv?.production && !fengEnv?.maintenance && fengSettings?.auth?.staging?.enable && (isAuthStaging | async)"
  ></fengstlayout-staging-bar>

  <fengstlayout-resale-bar
    *ngIf="!fengEnv?.maintenance && !fengSettings?.auth?.staging?.enable || (fengSettings?.auth?.staging?.enable && (isAuthStaging | async))"
  ></fengstlayout-resale-bar>

  <fengstlayout-header
    [isAuth]="(isAuth | async)"
    [isHome]="(isHome | async)"
    [isDependent]="(isDependent | async)"
    [isResale]="(isResale | async)"
    *ngIf="!fengEnv?.maintenance && (fengEnv?.production || (!fengSettings?.auth?.staging?.enable || (fengSettings?.auth?.staging?.enable && (isAuthStaging | async))))"
  ></fengstlayout-header>

  <div class="fengst-nav">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>

  <fengstlayout-footer
    [isAuth]="(isAuth | async)"
    [isHome]="(isHome | async)"
    [isDependent]="(isDependent | async)"
    [isResale]="(isResale | async)"
    *ngIf="!fengEnv?.maintenance && (fengEnv?.production || (!fengSettings?.auth?.staging?.enable || (fengSettings?.auth?.staging?.enable && (isAuthStaging | async))))"
  ></fengstlayout-footer>

</div>
